@import "Calendar.module.scss";

.habitRow {
    height: $colWidth;
    &:hover {
        background: black;
    }
} 

.habitLabel {
    max-width: 15rem;
    text-align: right;
    font-size: 1rem;
    padding-left: .5rem;
    padding-right: .5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.invalidDateCheck {
    cursor: default ;
    width: $colWidth;
}

.progress {
    font-size: 1rem;
    width: $colWidth;
    text-align: center;
}

.toolButton {
    width: $colWidth/2;
    padding-left: $colWidth/4;
    font-size: 1rem;
    &:hover {
        cursor: pointer;
        color: $todayColor;
    }
}

.handle {
    @extend .toolButton;
    padding-right: $colWidth/4;
    &:hover {
        cursor: grab;
        color: $todayColor;
    }
    &:active:hover {
        cursor:grabbing;
    }
}

.selected {
    background-color: black;
    cursor: grabbing;
    &:active {
        cursor: grabbing;
    }
}

.disabled {
    font-size: 1rem;
    height: $colWidth;
    background-color: black;
}
