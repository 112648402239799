@import "Calendar.module.scss";
//largely adapted from W3's example: 
//https://www.w3schools.com/css/css_tooltip.asp

.hasTooltip {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover .tooltip{
        visibility: visible;
        opacity: 1;
    }   
    .tooltip {
        &:hover {
            visibility: hidden;
            opacity: 0;
        }
        visibility: hidden;
        background-color: $todayColor;
        color: white;
        text-align: center;
        padding: .5rem;
        margin-bottom: 5rem;
        display: inline-block;
        position: absolute;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 1rem;
        border-radius: .5rem;
        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -.4rem;
            border-width: .4rem;
            border-style: solid;
            border-color: $todayColor transparent transparent transparent;
        }
    }
}
