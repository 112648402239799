@import "Calendar.module.scss";


.habitInput {
    text-align: center;
    justify-content: center;
    font-size: .66em;
    background-Color: black;
    padding-left: 2rem; 
    padding-right:2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: fixed;
    bottom: 1rem;
    border-radius: 1rem;
    margin-top: 3rem;
    input {
        margin-top:.2rem;
        &:focus {
            border:2px solid $todayColor 
         }
    }
   
}

.hideButton {
    margin-right: -1.5rem;
    position:relative;
    float:right;
    right: 1rem;
    color: white;
}

.addButton {
    text-align: center;
    width: fit-content;
    color: white;
    margin-left: auto;
    margin-right: auto;
}