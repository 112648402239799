h1 {
    margin-bottom:1.5rem;
}

h5 {
    margin: 0;
}

.accountManagement {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    p {
        font-size: 1rem;
    }
    form {
        border-radius: 2rem;
        background-color: black;
        width: 15%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
    label {
        font-size: 1rem;
    }
    
}