@import "Calendar.module.scss";

    
.cellWrapper {
    height: $colWidth;
    width: $colWidth;
    display: flex;
    align-items: center;
    justify-content: center;
    .cell {
        height:$colWidth * .7;
        width:$colWidth * .7;
        border-radius: 20%; 
        padding: 0px;
        display: inline-block;
        &.off {
            background-color: white;
        }
        &.on {
            background-color: $checkColor;
        }
        &:hover {
            cursor: pointer;
        }
    }
    &.today {
        background-color: $todayColor;
        padding: 0;
        .cell.on {
            border: 2px solid white;
        }
        &.over {
            background-color: $todayColor - #321A25;
            padding: 0px;
        }
    }
}


