$todayColor: #a8577b;
$checkColor: #2a9d8f;

$colWidth: 2.5rem;

$colWidthRatioOfRightSide: 3.5;
$widthOfRightSide: $colWidthRatioOfRightSide * $colWidth;
$widthOfAllDayColumns: 7 * $colWidth;

.calendarHeader {
  font-size: .75rem;
}

.dateHeader {
  padding-top:1rem;
  width: $colWidth;
  font-size: 1rem;
  padding-bottom:1rem;
}

.calendarButtons {
  padding: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 21rem;
  a {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: white;
  }
}

.today {
  background-color: $todayColor;
}

.calendar {
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  justify-content:center;
  flex-direction: column;
  align-items: center;
 
  
}


.tableWrapper {
  width: 100%;
  table {
    border: 0px;
    border-spacing: 0px;
    border-collapse: collapse;
    margin-right: calc(50% - calc(#{$widthOfRightSide} + #{$widthOfAllDayColumns}/2));
    float:right;
    &.addingHabit {
      margin-bottom: 8rem;
    }
  }
  ~ td {
    padding: 0px;
  }
  .spacer {
    width: $widthOfRightSide;
  }
}